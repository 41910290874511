$color_1: #fff;
$color_2: #818181;
$color_3: #f1f1f1;




//reset css
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Exo 2', sans-serif;
    letter-spacing: 1px;
    background-color: #eef4f7;
}

*,
::after,
::before {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

//Overrides
.hids_button {
    color: #f3af1c !important;
    background-color: #171c4c !important;
    height: 50px;
}

.hids_app {
    overflow: hidden;
    max-width: 1600px;
    margin: auto;
}


//OverRide difault css
.slick-track {
    height: auto !important;
}

.comming_soon {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 3em;
        text-align: center;
        color: #fff;
    }
}

//Header
.header {
    padding: 20px 5% 5px;
    display: flex;
    background: transparent;
    position: fixed;
    z-index: 999;
    width: 100%;
    transition: 0.5s;
    z-index: 9999;

    &.fill {
        background: #fff;
        box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px -3px rgba(0, 0, 0, 0.12);
        transition: 0.5s;

        .header_right {
            .header_menu {
                .menu_list {
                    li {
                        color: #001236;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .header_left {
        display: flex;
        align-items: center;

        svg {
            width: 120px;
        }
    }

    .header_right {
        margin-left: 50px;

        .header_info {
            height: 23px;
        }

        .header_menu {
            height: 20px;

            .menu_list {
                display: flex;

                li {
                    margin: 0 20px 20px;
                    cursor: pointer;
                    color: #001236;

                    a {
                        color: inherit;
                        text-decoration: none;
                        position: relative;

                        &.active::after {
                            transform: scaleX(1);
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 25px;
                            bottom: 0;
                            height: 3px;
                            width: 100%;
                            transition: transform .25s ease-in-out;
                            background-color: #f4af1b;
                            transform: scaleX(0);
                        }
                    }

                    &:hover {
                        a {
                            &::after {
                                transform: scaleX(1);
                            }
                        }

                        .sub_menu {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(16px);
                        }
                    }
                }
            }

            .sub_menu {
                opacity: 0;
                visibility: hidden;
                flex-direction: column;
                padding: 20px 10px;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                transform: translateY(20px);
                transition: 0.5s;
                border-radius: 5px;

                &>li {
                    margin-bottom: 15px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #f9f9f9;
                }

                &>ul {
                    max-height: 80vh;
                    overflow-y: auto;

                    .submenu_list_container {
                        margin: 0;
                        cursor: pointer;
                        color: #fff;
                        padding: 8px;
                        margin-bottom: 5px;
                        transition: background 0.5s;

                        &:hover {
                            background: #eee;
                            border-radius: 5px;
                        }

                        a {
                            &::after {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .submenu_title {
                margin-bottom: 8px;
                font-weight: 600;
                color: #1e2548;
            }

            .submenu_list {
                margin-bottom: 5px !important;
                font-size: 0.75em;
                color: #4c4c4c !important;
                list-style: disc;
            }
        }
    }

    .mobile_menu_button {
        display: none;
    }
}

.drawer_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
}

.mobile_menu {
    .MuiList-padding {
        padding: 0 !important;
    }

    .MuiPaper-root {
        width: 95%;
        background-color: #171c4c !important;
    }

    .MuiListItem-button {
        border-top: 1px solid #f3af1c;

        .MuiSvgIcon-root {
            color: #fff;
        }
    }

    .sub_menu_list {
        margin-left: 20px;
        margin-bottom: 10px;

        &>li {
            &>div {
                border-top: 0px !important;
            }

            a {
                color: #ddd;
            }
        }
    }

    .menu_itms {
        margin: 4px 0;
        color: #f3af1c;
        font-size: 1em;
        line-height: 24px;
        width: 100%;
    }
}

.sec {
    padding: 50px 5%;
}

.hero {
    height: 700px;
    background-image: url('./images/bg/bg1.jpeg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    padding-bottom: 0;

    .left {
        width: 50%;
        padding-top: 150px;

        .info {
            margin-bottom: 50px;

            .title {
                font-size: 1.2em;
                margin-bottom: 20px;
                color: #171c4c;
                font-weight: 900;
                letter-spacing: 2px;
            }

            .heading {
                font-size: 3.5em;
                margin-bottom: 30px;
                font-weight: 600;
                color: #171c4c;
                line-height: 1.2em;
            }

            .sub_heading {
                font-size: 1.8em;
                line-height: 1.2em;
                margin-bottom: 10px;
            }
        }

        .more {
            display: flex;

            .tell_me_more {
                height: 43px;
                width: 175px;
                border: none;
                text-align: center;
                border-radius: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
            }
        }
    }

    .right {
        width: 50%;
        padding-left: 20px;
        display: flex;
        justify-content: flex-end;

        img {
            width: 90%;
        }

    }
}

.solution_header {
    background-color: #fff;
    margin-bottom: -30px;
    margin-top: -30px;

    path {
        fill: #fafafa;
    }
}

.leadership_header {
    background-color: #fff;
    margin-bottom: -30px;

    path {
        fill: #fafafa;
    }
}

.solution_banner {
    min-height: 500px;
    color: #fff;
    padding: 50px 5%;
    display: flex;

    .info {
        width: 60%;
        padding-right: 10px;

        .title {
            margin: 100px 0 60px;
            font-weight: 100;
            font-size: 1.1em;
        }

        .heading {
            margin-bottom: 50px;
            font-weight: 600;
            font-size: 1.7em;
            line-height: 1.5em;
        }

        .sub_heading {
            margin-bottom: 10px;
            font-weight: 300;
            font-size: 1.3em;
            line-height: 1.3em;
        }
    }

    .img {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            width: 90%;
        }
    }

}

.brand_strap {
    display: flex;
    background-color: #eee;
    height: 120px;
    align-items: center;
    justify-content: center;

    .brand {
        margin: 5px;
    }
}

.banner_wrapper {
    min-height: 500px;

    // display: flex;
    // .banner_thumbnail{
    //    width: 215px;
    //    margin-left: 5px;
    //    margin-top: -1px;
    // }
    // .banner_main{
    //     width: calc(100% - 100px);
    // }
    // .slick_thumb_slide{
    //     cursor: pointer;
    // }
    .banner {
        position: relative;
    }

    .slick-slide-title {
        color: #fff;
        padding: 10px;
        position: absolute;
        font-size: 3em;
        bottom: 10px;
        width: 100%;
        text-shadow: 1px 1px #3c3c3c;
        text-align: center;
        bottom: 60px;
    }

    .slick-slide-image {
        width: 100%;
    }

    .slick-next {
        right: 20px;
    }

    .slick-prev {
        left: 10px;
        z-index: 999;
    }

    .slick-dots {
        bottom: 10px;
    }

    .slick-dots li button:before {
        font-size: 15px;
    }

    .slick-next:before,
    .slick-prev:before {
        font-size: 30px;
    }
}

//About Section
.about_sec {
    display: flex;
    padding: 0 50px 5%;
    background-color: #fff;

    .left {
        width: 50%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        background-image: url('./images//bg/about_left_bg.svg');

        ul {
            li {
                font-size: 1.5em;
                line-height: 1.2em;
                margin-bottom: 20px;
                list-style-type: disc;
            }

            // .line_break{
            //     display: block;
            //     margin-bottom: 20px;
            // }
            .count_up {
                font-weight: 900;
                color: #001236;
            }
        }
    }

    .right {
        width: 50%;
        display: flex;
        flex-wrap: wrap;

        .info {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            margin: 5px;
            background: #eee;

            img {
                max-width: 100px;
                margin: 20px 0;
            }

            .title {
                width: 100%;
                padding: 5px;
                text-align: center;
                background-color: #1e2548;
                color: #f4af1b;
                min-height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .count {
                font-size: 2em;
                margin-bottom: 20px;
            }
        }
    }
}

//Blog section
.blog_sec {
    display: flex;
    padding: 50px 5%;

    .blog_left {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        h2 {
            padding: 5% 0;
            font-size: 2.8em;
            font-weight: 900;
            text-align: left;
        }

        p {
            font-size: 1.2em;
            line-height: 24px;
        }
    }

    .blog_right {
        width: 70%;
        display: flex;
        justify-content: flex-end;
    }

    .blog_card {
        border: 1px solid #eee;
        margin: 10px;
        width: 250px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px #0000001a;
    }

    .blog_card_thumbnail {
        height: 150px;
        background-size: cover;
    }

    .blog_card_info {
        padding: 10px;

        .title {
            font-size: 1em;
            min-height: 150px;
        }
    }

    .blog_card_footer {
        font-size: 0.8em;
    }
}

//Footer
.footer {
    .footer_header {
        background-color: #fafafa;
        margin-bottom: -60px;

        path {
            fill: #0070c0;
        }
    }

    li {
        margin-bottom: 8px;

        a {
            color: #1e2548;
            position: relative;
            font-size: 0.9em;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 18px;
                bottom: 0;
                height: 2px;
                width: 100%;
                transition: transform .25s ease-in-out;
                background-color: #f4af1b;
                transform: scaleX(0);
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }

    .upper_footer {
        background-color: #0070c0;
        padding: 50px 5%;
        color: #fff;

        .title {
            font-size: 1.5em;
            margin-bottom: 30px;
        }

        h4 {
            color: #bae6ff;
            font-size: 1em;
            margin-bottom: 15px;
        }

        h2 {
            font-size: 1.3em;
            margin-bottom: 10px;

            &:after {
                content: '';
                margin-bottom: 30px;
                border-bottom: 1px solid #fff;
                width: 100px;
                display: block;
                margin-top: 20px;
            }
        }

        p {
            color: #bae6ff;
            margin-bottom: 50px;
            line-height: 20px;
            min-height: 100px;
        }

        button {
            border: 1px solid #fff;
            background: transparent;
            padding: 10px 20px;
            color: #fff;
            min-width: 150px;
            text-align: left;
            cursor: pointer;
            transition: .5s;
            border-radius: 0;
            box-shadow: none;

            &:hover {
                background-color: #fff;
                color: #0070c0;
                border: 1px solid #0070c0;
            }
        }

        .upper_footer_container {
            display: flex;
            flex-wrap: wrap;

            .contact_us {
                width: 30%;
                margin-right: 2%;
            }

            .free_demo {
                width: 30%;
                margin-right: 2%;
            }

            .business {
                width: 30%;
            }
        }
    }

    .middle_footer {
        padding: 50px 5% 40px;
        display: flex;
        flex-wrap: wrap;

        .logo {
            &::after {
                content: "";
                display: none;
            }
        }

        .title {
            margin-bottom: 10px;
            font-size: 1.2em;
        }

        .about_hids {
            width: 15%;

            svg {
                max-width: 80px;
                margin-bottom: 10px;
            }

            h3 {
                margin-bottom: 30px;
            }
        }

        .hids_ftr_links {
            width: 15%;
        }

        .solution_links {
            width: 35%;
        }

        .resources {
            width: 20%;
        }

        .company {
            width: 15%;
        }

    }

    .lower_footer {
        padding: 5px 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #eee;

        .social_links {
            a {
                color: #171c4c;
                margin: 5px;
            }
        }

        .other_links {
            ul {
                display: flex;

                li {
                    margin-bottom: 0;

                    &:first-child {
                        margin-right: 10px;
                    }
                }

            }
        }
    }

}

//not found page
.not_found {
    height: 90vh;

    svg {
        display: flex;
        margin: auto;
        max-width: 50%;
    }
}

//platfoem page
.platform {
    padding-top: 100px;

    .platform_banner {
        display: flex;
        height: 600px;

        .left {
            width: 60%;
            padding: 100px 5%;

            h1 {
                font-size: 2em;
                margin-bottom: 30px;
                line-height: 1.2em;
            }

            h5 {
                font-size: 1.3em;
                margin-bottom: 30px;
                line-height: 1.2em;
            }

            .free_trail {
                input {
                    height: 34px;
                    width: 300px;
                    border: 1px solid #3f51b5;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-right: none;
                }

                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    box-shadow: none;
                    height: 34px;
                    margin-top: -2px;
                    font-family: 'Exo 2', sans-serif;
                }
            }
        }

        .right {
            width: 40%;
            display: flex;
            align-items: center;

            svg {
                max-width: 100%;
            }
        }
    }

    .steps {
        background-color: #612395;
        display: flex;
        justify-content: center;
        align-items: center;

        .step {
            height: 100px;
            width: 150px;
            background-color: rgba(255, 255, 255, 0.42);
            color: #fff;
            border: 1px solid #fff;
            border-left: none;
            padding: 10px;
            cursor: pointer;

            &:first-child {
                border-left: 1px solid #fff;
            }
        }
    }

    .platform_sec {
        min-height: 500px;
        display: flex;
        padding: 50px 5%;

        .left {
            width: 50%;
        }

        .right {
            width: 50%;
        }

        .sec_title {
            margin-bottom: 30px;
            font-size: 1em;
        }

        .sec_heading {
            font-size: 1.8em;
            line-height: 1.2em;
            margin-bottom: 30px;
            padding-left: 6%;
        }

        .sub_heading {
            font-size: 1.2em;
            line-height: 1.2em;
            padding-left: 6%;
        }
    }

    .design,
    .distribution {
        background-color: #002a7e;
        color: #bfbfbf;
    }

    .integration,
    .training {
        background-color: #001236;
        color: #bfbfbf;
    }

    .our_solutions {
        background-color: #612395;
        color: #fff;
    }
}

//Faq page
.faqs {
    padding-bottom: 50px;

    .faqs_jumbotron {
        padding: 130px 5% 50px;
        color: #171c4c;
    }

    .heading {
        font-size: 2em;
        margin: 50px 0 20px 0;
        text-align: center;
        // color: #1e2548;
    }

    .sub_heading {
        font-size: 1.2em;
        margin-bottom: 50px;
        text-align: center;
        // color: #1e2548;
    }

    .faq_container {
        margin: 0 15%;
    }

    .answer {
        color: #565656;
        font-size: 1em;
    }
}

//Login Page
.login {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.login_container {
    width: 500px;
    padding: 50px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-radius: 5px;

    button {
        height: 50px;
        margin-top: 20px;
    }

}

//Demo page
.demo {
    .demo_jumbotron {
        padding: 200px 5% 100px;
        color: #171c4c;
        background-color: #fff;

        h1 {
            font-size: 2em;
            text-align: center;
            margin-bottom: 20px;
        }

        p {
            text-align: center;
            font-size: 1em;
            line-height: 1.2em;
            font-weight: 100;
        }
    }

    .demo_container {
        display: flex;
        min-height: 600px;

        .left {
            width: 50%;
            background-color: #1e2548;
            color: #fff;
            padding: 50px;

            .info {
                margin-bottom: 30px;
            }

            h1 {
                margin-bottom: 20px;
                font-size: 1.8em;
                line-height: 1.2em;
            }

            h2 {
                font-size: 1.5em;
            }

            h3 {
                margin-bottom: 10px;
                font-size: 1.2em;
            }

            p {
                font-size: 1em;
                line-height: 1.2em;
                font-weight: 100;
            }
        }

        .right {
            width: 50%;
            padding: 50px;
            color: #1e2548;

            button {
                height: 50px;
                margin-top: 20px;
            }
        }
    }

}

//Contact & Sales page
.contact {
    padding: 100px 5% 50px;
    background-color: #f9faf8;

    .location_section {
        .location_card {
            margin: 0 auto;
            padding: 16px;
            background-color: #fff;
            border: none;
            border-radius: 5px;
            box-shadow: 0 0 4px 0 rgba(13, 16, 7, .04), 0 7px 15px -2px rgba(13, 16, 7, .08);
            width: 30%;
            overflow: hidden;

            img {
                max-width: 100%;
            }

            h3 {
                font-size: 1.625rem;
                line-height: 32px;
                margin: 12px auto;
            }

            p {
                font-size: 1.125rem;
                margin-bottom: 24px;
            }
        }

        .locations {
            display: flex;
            margin: 50px 0 100px;
        }
    }

    .contact_section {
        h1 {
            font-size: 2.2rem;
            line-height: 48px;
            text-align: center;
            font-weight: 600;
            margin-bottom: 50px;
            padding: 0 20%;
        }

        .contact_wrapper {
            display: flex;
        }

        .contact_form {
            width: 45%;
            margin-right: 5%;
        }

        .team {
            width: 50%;
            border-radius: 5px;
            background-color: #fff;
            margin-bottom: 40px;
            box-shadow: 0 2px 6px 0 rgba(123, 124, 122, .22), 0 13px 12px -11px rgba(180, 181, 176, .32);
            padding: 16px;
            margin: 100px auto;
        }

        .team_list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;

            .person {
                padding: 20px;
                text-align: center;
                margin-bottom: 50px;
                margin-top: -80px;

                img {
                    border-radius: 100%;
                    display: block;
                    margin: 0 auto;
                    width: 100px;
                    height: 100px;
                    box-shadow: 0 2px 6px 0 rgba(123, 124, 122, .22), 0 13px 12px -11px rgba(180, 181, 176, .32);
                    padding: 10px;
                    background: #fff;
                }

                .designation,
                .name,
                .email,
                .contact_number {
                    font-size: 1em;
                    margin: 5px 0;
                }

                .name,
                .email,
                .contact_number {
                    font-weight: 600;
                }
            }
        }
    }
}

//Sloution page
.solution_sec {
    padding: 30px 5%;
    background-color: #fafafa;

    .heading {
        font-size: 2em;
        color: #001236;
        text-align: center;
        font-weight: 600;
        margin-bottom: 30px;
    }
}

.solution_list_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.sloution_card {
    margin: 5px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    background: #fff;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
    width: 32.2%;
    position: relative;
    min-height: 220px;
    border: 1px solid #eee;

    &:hover {
        img {
            transform: scale(1.5);
        }
    }

    .meta {
        height: 100px;
        display: flex;
        align-items: center;
        padding: 10px;
        overflow: hidden;
        position: relative;

        img {
            height: 90px;
            transition: 0.25s;
            position: absolute;
            opacity: 0.3;
            z-index: 1;
        }

        .title {
            color: #fff;
            text-align: center;
            width: 100%;
            font-weight: 600;
            font-size: 1.1em;
            z-index: 2;
        }
    }

    .description {
        padding: 10px 10px 30px;
        position: relative;
        z-index: 99;

        .desc {
            font-size: 0.8em;
            margin-bottom: 10px;
        }
    }

    .read-more {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 15px;
        transition: 0.5s;
        z-index: 99;
        font-weight: 600;

        a {
            color: #171c4c;
        }

        &:hover {
            right: 10px;
        }
    }
}

.key_benifits_container {
    padding: 50px 5%;

    h2 {
        text-align: center;
        color: #171c4c;
        font-size: 2em;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .key_benifits {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .benifts_card {
            width: 28%;
            margin: 10px;
            min-height: 400px;
            padding: 1rem;
            background-color: #f9f9f9;
            border-radius: 8px;
            box-shadow: 0 12px 32px 4px rgba(0, 0, 0, 0.2);
            transition: .2s;

            &:hover {
                transform: translateY(-5px);
            }

            .title {
                font-size: 1.1em;
                min-height: 100px;
                line-height: 1.1em;
                text-align: center;
                font-weight: 600;
                margin-top: 20px;
            }

            .desc {
                margin-bottom: 20px;
                font-size: 0.9em;
                line-height: 18px;
            }
        }
    }
}

.use_cases {
    display: flex;
    padding: 50px 5%;
    background-color: #0070c0;

    .left {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        .title {
            font-size: 1.4em;
            color: #fff;
            text-align: center;
            margin-bottom: 50px;
        }

        img {
            max-width: 100%;
        }
    }

    .right {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
    }

    .usecases_card {
        background-color: #fff;
        padding: 15px;
        width: 45%;
        min-height: 300px;

        &:nth-child(1),
        &:nth-child(2) {
            border-bottom: 1px solid #0070c0;
        }

        &:nth-child(1),
        &:nth-child(3) {
            border-right: 1px solid #0070c0;
        }

        .heading {
            font-size: 1.1em;
            font-weight: 600;
        }

        .desc {
            margin-bottom: 20px;

            h3 {
                font-size: 1em;
                font-weight: 600;
                margin-bottom: 5px;
            }

            p {
                font-size: 0.9em;
                line-height: 18px;
            }
        }
    }
}

.features_container {
    padding: 50px 5%;
    display: flex;
    background-color: #f9f9f9;

    .left {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            text-align: center;
            color: #171c4c;
            font-size: 3em;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }

    .features {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;

        .features_card {
            width: 100%;
            margin: 5px;
            min-height: 150px;
            padding: 1rem;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;
            border: 1px solid #eee;
            transition: .2s;

            .title {
                font-size: 1.1em;
                line-height: 1.1em;
                font-weight: 600;
                margin: 20px 0;
                color: #171c4c;
            }

            .desc {
                margin-bottom: 10px;
                font-size: 0.9em;
                line-height: 18px;
            }
        }
    }
}

.additional_features_container {
    padding: 50px 5%;
    display: flex;

    .left {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            text-align: center;
            color: #171c4c;
            font-size: 2em;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }

    .additional_features {
        width: 70%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .additional_features_card {
            width: 29%;
            margin: 1px;
            min-height: 400px;
            padding: 1rem;
            background-color: #fff;

            .title {
                font-size: 1.1em;
                min-height: 100px;
                line-height: 1.1em;
                text-align: center;
                font-weight: 600;
                margin-top: 20px;
            }

            .desc {
                margin-bottom: 20px;
                font-size: 0.9em;
                line-height: 18px;
            }
        }
    }
}





figure.profile_card {
    position: relative;
    overflow: hidden;
    margin: 10px;
    width: 46%;
    background: #ffffff;
    color: #000000;
}

figure.profile_card * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

figure.profile_card>img {
    width: 50%;
    border-radius: 50%;
    border: 4px solid #ffffff;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    position: relative;
    float: right;
    right: -15%;
    z-index: 1;
}

figure.profile_card figcaption {
    padding: 20px 30px 20px 20px;
    position: absolute;
    left: 0;
    width: 50%;
}

figure.profile_card figcaption h2,
figure.profile_card figcaption p {
    margin: 0;
    text-align: left;
    padding: 10px 0;
    width: 100%;
}

figure.profile_card figcaption h2 {
    font-size: 1.3em;
    font-weight: 600;
    color: #171c4c;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

figure.profile_card figcaption h2 span {
    font-weight: 800;
}

figure.profile_card figcaption p {
    font-size: 0.9em;
    opacity: 0.8;
}

figure.profile_card figcaption .icons {
    width: 100%;
    text-align: left;
}

figure.profile_card figcaption .icons i {
    font-size: 26px;
    padding: 5px;
    top: 50%;
    color: #171c4c;
}

figure.profile_card figcaption a {
    opacity: 0.3;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.profile_card figcaption a:hover {
    opacity: 0.8;
}

figure.profile_card .position {
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    font-size: 0.9em;
    opacity: 1;
    font-style: italic;
    color: #171c4c;
    background: #f3af1c;
    clear: both;
    font-weight: 600;
}

figure.profile_card:hover>img,
figure.profile_card.hover>img {
    right: -12%;
}

.profile_lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


//About us page
.about_us {
    .info {
        padding: 150px 5% 50px;
        background-color: #fff;

        h1 {
            text-align: center;
            color: #171c4c;
            font-size: 2em;
            font-weight: 600;
            margin-bottom: 50px;
        }

        p {
            font-size: 1.1em;
            line-height: 25px;
            min-height: 0;
            margin-bottom: 15px;
            color: #484848;
        }
    }

    .leadership {
        padding-bottom: 100px;

        .heading {
            text-align: center;
            color: #171c4c;
            font-size: 2em;
            font-weight: 600;
            margin-bottom: 50px;
        }

        h2 {
            text-align: left;
            color: #171c4c;
            font-size: 1.2em;
            font-weight: 600;
        }

        p {
            line-height: 18px;
        }
    }
}

.logo {
    width: 100px;
}

.footer_logo {
    width: 100px;
}

//Career Page
.career {
    padding: 50px 5%;
    display: flex;

    .career_card {
        width: 50%;
        border-radius: 5px;
        background-color: #fff;
        margin-bottom: 40px;
        box-shadow: 0 2px 6px 0 rgba(123, 124, 122, 0.22), 0 13px 12px -11px rgba(180, 181, 176, 0.32);
        padding: 16px;
        margin: 100px auto;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .sloution_card {
        width: 100%;
    }

    .header {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        .header_right {
            display: none;
            padding: 0;
        }

        .header_left {
            .logo {
                width: 70px;
            }

            svg {
                width: 40px;
            }
        }

        .mobile_menu_button {
            display: block;
        }
    }

    .footer {
        li {
            margin-bottom: 5px;
            font-size: 0.8em;
        }

        .upper_footer {
            padding: 20px;

            .upper_footer_container {

                .contact_us,
                .free_demo,
                .business {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .title {
                font-size: 1.2em;
            }

            p {
                font-size: 0.8em;
                line-height: 17px;
                min-height: 0;
                margin-bottom: 5px;
            }

            button {
                font-size: 0.8em;
                padding: 2px 4px;
                min-width: 120px;
            }

            h2 {
                font-size: 1em;

                &::after {
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
            }

            h4 {
                margin-bottom: 6px;
            }
        }

        .middle_footer {
            padding: 20px;

            .about_hids {
                display: none;
            }

            .hids_ftr_links,
            .solution_links,
            .resources,
            .company {
                width: 100%;
                margin-bottom: 10px;
            }

            .title {
                margin-bottom: 5px;
                font-size: 1em;
            }
        }

        .lower_footer {
            padding: 5px;
            background-color: #1e2548;
            flex-direction: column;

            .social_links {
                margin-bottom: 10px;

                a {
                    color: #f4af1b;

                    svg {
                        width: 20px;
                    }
                }
            }

            .copy_writes {
                font-size: 0.6em;
                color: #f4af1b;
            }

            .other_links {
                margin-top: 10px;

                li {
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .solution_banner {
        height: auto;
        flex-wrap: wrap;

        .info {
            width: 100%;

            .title {
                margin: 60px 0 20px;
            }

            .heading {
                font-size: 1em;
                line-height: 1.3em;
                margin-bottom: 20px;
            }

            .sub_heading {
                line-height: 1.3em;
            }
        }

        .img {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .key_benifits_container {
        padding: 20px 5%;

        h2 {
            font-size: 1.2em;
            margin-bottom: 20px;
        }

        .key_benifits {
            .benifts_card {
                width: 100%;
                min-height: auto;

                .title {
                    font-size: 1em;
                    min-height: auto;
                }
            }
        }
    }

    .features_container {
        flex-wrap: wrap;
        padding: 20px 5%;

        .left {
            width: 100%;

            h2 {
                font-size: 1.3em;
                margin-bottom: 20px;
            }
        }

        .right {
            width: 100%;
        }

        .features {
            width: 100%;
        }
    }

    .additional_features_container {
        flex-wrap: wrap;
        padding: 20px 5%;

        .left {
            width: 100%;

            h2 {
                font-size: 1.3em;
                margin-bottom: 20px;
            }
        }

        .additional_features {
            width: 100%;

            .additional_features_card {
                width: 100%;
                margin: 5px;
                padding: 1rem;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;
                border: 1px solid #eee;
                transition: .2s;
                min-height: auto;

                .title {
                    min-height: auto;
                    margin-bottom: 20px;
                }
            }
        }

    }

    .use_cases {
        flex-wrap: wrap;
        padding: 20px 5%;

        .left {
            width: 100%;

            .title {
                font-size: 1em;
                line-height: 20px;
            }
        }

        .right {
            width: 100%;
        }

        .usecases_card {
            width: 100%;
            border: none !important;
            margin-bottom: 5px;
        }
    }

    .hero {
        height: 400px;

        .left {
            padding-top: 60px;

            .more {
                .tell_me_more {
                    height: 35px;
                    width: 150px;
                    font-size: 1em;
                }
            }

            .info {
                margin-bottom: 25px;

                .heading {
                    font-size: 1.5em;
                }

                .sub_heading {
                    font-size: 1em;
                    margin-bottom: 0;
                }
            }
        }

        .right {
            padding-left: 0;

            img {
                width: 100%;
                margin-bottom: 75px;
            }
        }
    }

    .banner_wrapper {
        min-height: 100px;

        .slick-slide-title {
            font-size: 1.1em;
        }

        .slick-next:before,
        .slick-prev:before {
            font-size: 17px;
        }
    }

    .about_sec {
        flex-wrap: wrap;
        padding: 20px;

        .left {
            width: 100%;
            margin-bottom: 10px;
            padding: 0;

            h2 {
                font-size: 1em;

                .line_break {
                    display: initial;
                }
            }

            ul {
                margin: 20px 0;

                li {
                    font-size: 1em;
                    margin: 5px 0 5px 20px;
                }
            }
        }

        .right {
            width: 100%;

            .info {
                width: 48%;
                justify-content: space-between;

                &:nth-child(even) {
                    margin-right: 0;
                }

                &:nth-child(odd) {
                    margin-left: 0;
                }

                .title {
                    font-size: 0.8em;
                    min-height: 36px;
                }

                img {
                    max-width: 70px;
                    margin: 10px 0;
                }
            }
        }
    }

    .blog_sec {
        flex-wrap: wrap;
        padding: 20px;

        .blog_left {
            width: 100%;
        }

        .blog_right {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .solution_header {
        margin-bottom: 0;
    }

    .solution_list_container {
        margin-left: 0;
        margin-right: 0;
    }

    .faqs {
        .faqs_jumbotron {
            padding: 80px 5% 10px;
        }

        .heading {
            font-size: 1.4em;
        }

        .sub_heading {
            font-size: 1em;
            margin-bottom: 50px;
        }

        .faq_container {
            margin: 0 5%;
        }
    }

    .login_container {
        width: 80%;
        padding: 30px;
    }

    .contact {
        .contact_section {
            .team {
                width: 90%;
            }
        }
    }

    .demo {
        .demo_jumbotron {
            padding: 91px 5% 20px;

            h1 {
                font-size: 1.2em;
                line-height: 25px;
                font-weight: 600;
            }

            p {
                text-align: justify;
            }
        }

        .demo_container {
            flex-wrap: wrap;

            .left {
                width: 100%;
                padding: 25px 5%;

                h2 {
                    font-size: 1em;
                    font-weight: 600;
                }

                h3 {
                    font-size: 1em;
                }

                p {
                    font-size: 0.8em;
                    line-height: 18px;
                }
            }

            .right {
                width: 100%;
                padding: 25px 5%;

                h2 {
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .platform {
        padding-top: 0;

        .platform_banner {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            height: auto;

            .left {
                width: 100%;
                padding: 20px 5%;

                h1 {
                    font-weight: 600;
                    font-size: 1em;
                }

                h5 {
                    font-size: 0.9em;
                }

                .free_trail {
                    input {
                        width: 55%;
                    }

                    button {
                        font-size: 0.6em;
                    }
                }
            }

            .right {
                width: 100%;
                display: flex;
                justify-content: center;
                padding-top: 20px;

                svg {
                    max-width: 80%;
                    max-height: 300px;
                }
            }
        }

        .steps {
            flex-wrap: wrap;

            .step {
                width: 33%;
                height: 60px;
                border-left: 1px solid #fff;

                h3 {
                    font-size: 0.8em;
                }
            }
        }

        .platform_sec {
            flex-wrap: wrap;
            padding: 20px 5%;
            min-height: auto;

            .left,
            .right {
                width: 100%;
            }

            .sec_heading {
                font-size: 1em;
                font-weight: 600px;
            }

            .sub_heading {
                font-size: 1em;
                font-weight: 100;
            }
        }
    }

    .about_us {
        .info {
            padding: 88px 5% 10px;

            h1 {
                margin-bottom: 23px;
            }

            p {
                font-size: 0.9em;
                text-align: justify;
            }
        }

        .leadership {
            .heading {
                margin-bottom: 20px;
                font-size: 1.3em;
            }

            figure.profile_card {
                width: 100%;

                figcaption {
                    position: static;
                    width: 75%;
                }

                &>img {
                    position: absolute;
                    top: 0;
                    width: 25%;
                    right: 0;
                }
            }
        }
    }

    .career {
        .career_card {
            width: 100%;
            margin: 30px auto;
        }
    }
}

.swal2-container {
    z-index: 9999999999999999 !important;
}

.welcome_popup_container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #000;
    animation: iconosani 1.2s forwards;
    display: flex;
    height: 100%;
    width: 72%;
    gap: 10px;
    z-index: 999999;
    max-height: 95%;

    .close_btn {
        color: #464646;
        right: 8px;
        top: 0px;
        position: absolute;
        margin-right: -15px;
        margin-top: -15px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 10px #000;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        line-height: 1rem;
        cursor: pointer;
}
.obj_container{
    width: 50%;
    max-height: 95vh;
    overflow: auto;
    object{
        width: 100%;
        height: 90vh;
    }
    }
}


@-webkit-keyframes iconosani {
    0% {
       
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}